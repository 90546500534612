import { useForm } from "react-hook-form";
import { faFloppyDisk, faShare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Checkbox, FormButtonLoading } from ".";
import { ResponseStateEnum, TextProblemTypeEnum } from "../Utils";
import { DefaultErrorsType, FormErrors, TextProblemFormValue, TextProblemType } from "../types";
import { getOperators } from "../Utils/TextProblemTypeEnum";
import { Alert } from "react-bootstrap";

const defaultValues: TextProblemFormValue = {
  primaryType: "normal",
  title: "",
  statement: "",
  nivel: "",
  type: "",
  public: "yes",
  response: "",
  isExclude: false,
};

const defaultErrors: Omit<DefaultErrorsType<TextProblemFormValue>, "isExclude"> = {
  primaryType: {
    required: "Veuillez indiquer le type du problème.",
  },
  title: {
    required: "Veuillez renseigner le titre du problème.",
    maxLength: { value: 64, message: "Merci de saisir moins de 64 caractères." },
  },
  statement: {
    required: "Veuillez renseigner l'énoncé du problème.",
    maxLength: { value: 500, message: "Merci de saisir moins de 500 caractères." },
  },
  nivel: {
    required: "Veuillez renseigner le niveau du problème.",
  },
  type: {
    validate: (value, formValues) => (value !== "" && formValues.primaryType === "normal") || "Veuillez spécifier le type du problème.",
  },
  public: {
    required: "Veuillez choisir la visibilité de votre problème.",
  },
  response: {
    maxLength: { value: 16, message: "Merci de saisir moins de 16 caractères." },
  },
};

type TextProblemFormProps = {
  onFormSubmit: (data: TextProblemFormValue) => void;
  formErrors: FormErrors<TextProblemFormValue>;
  handleCancel: () => void;
  titleLabel: string;
  cancelLabel?: string;
  isLoading?: boolean;
  isSuccess?: boolean;
  problem?: TextProblemType;
};

function TextProblemForm({ onFormSubmit, formErrors, handleCancel, titleLabel, cancelLabel = "Annuler", isLoading = false, isSuccess = false, problem = undefined }: Readonly<TextProblemFormProps>) {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  });

  // To set the errors to the form
  useEffect(() => {
    if (formErrors.length === 0) {
      reset(defaultValues);
    }
    formErrors.forEach(({ name, message }) => {
      setError(name, { message: message });
    });
  }, [formErrors, setError, reset]);

  // To reset the form if the mutation was successfull
  useEffect(() => {
    if (isSuccess) reset();
  }, [isSuccess, reset]);

  // To prepopulate the form based on default value (used in modify and review part, not in contribute)
  useEffect(() => {
    if (problem) {
      if (problem.type === "step" || problem.type === "propor" || problem.type === "frac" || problem.type === "english") {
        setValue("primaryType", problem.type);
      } else {
        setValue("primaryType", "normal");
        setValue("type", problem.type);
      }
      setValue("title", problem.title);
      setValue("statement", problem.statement);
      setValue("nivel", problem.nivel);
      setValue("response", problem.response);
      setValue("isExclude", problem.isExclude);
    }
  }, [problem, setValue]);

  return (
    <div className="form-template">
      <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <div className="label-form">
          <h1 className="h3 text-center">{titleLabel} un problème</h1>
        </div>
        <div className="alert-form">{errors.root && <Alert variant="danger">{errors.root.message}</Alert>}</div>
        <div className="body-form">
          {/* Primary Type */}
          <div className="text-center">
            <fieldset className="mb-3">
              <Checkbox label="Basique" name="primaryType" value="normal" register={register} error={errors.primaryType} defaultErrors={defaultErrors.primaryType} />
              <Checkbox label="À étapes" name="primaryType" value="step" register={register} error={errors.primaryType} defaultErrors={defaultErrors.primaryType} />
              <Checkbox label="Proportionnalité" name="primaryType" value="propor" register={register} error={errors.primaryType} defaultErrors={defaultErrors.primaryType} />
              <Checkbox label="Avec fractions" name="primaryType" value="frac" register={register} error={errors.primaryType} defaultErrors={defaultErrors.primaryType} />
              <Checkbox label="En anglais" name="primaryType" value="english" register={register} error={errors.primaryType} defaultErrors={defaultErrors.primaryType} />
              {errors.primaryType && <div className="invalid-feedback d-block">{errors.primaryType.message}</div>}
            </fieldset>
          </div>

          {/* Title */}
          <div className="mb-3 form-floating">
            <input
              type="text"
              {...register("title", defaultErrors.title)}
              id="text_problem_title"
              className={"form-control" + (errors.title ? " is-invalid" : "")}
              placeholder="Titre"
              required
              autoFocus
            />
            <label htmlFor="text_problem_title" className="form-label">
              Titre
            </label>
            {errors.title && <div className="invalid-feedback d-block">{errors.title.message}</div>}
          </div>

          {/* Statement */}
          <div className="mb-3 form-floating">
            <textarea
              {...register("statement", defaultErrors.statement)}
              id="text_problem_statement"
              className={"form-control" + (errors.statement ? " is-invalid" : "")}
              placeholder="Énnoncé du problème"
              style={{ height: 150 }}
              maxLength={500}
              required
            ></textarea>
            <label htmlFor="text_problem_statement" className="form-label">
              Énoncé du problème
            </label>
            <div id="text_problem_statement_help" className="form-text mb-0">
              {watch("statement").length}/500
            </div>
            {errors.statement && <div className="invalid-feedback d-block">{errors.statement.message}</div>}
          </div>

          {/* Type */}
          <div className="text-center">
            <fieldset className="mb-3">
              <legend className="col-form-label">Niveau</legend>
              <Checkbox label="CP" name="nivel" value="cp" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
              <Checkbox label="CE1" name="nivel" value="ce1" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
              <Checkbox label="CE2" name="nivel" value="ce2" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
              <Checkbox label="CM1" name="nivel" value="cm1" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
              <Checkbox label="CM2" name="nivel" value="cm2" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
              <Checkbox label="Sixième" name="nivel" value="6eme" register={register} error={errors.nivel} defaultErrors={defaultErrors.nivel} />
              {errors.nivel && <div className="invalid-feedback d-block">{errors.nivel.message}</div>}
            </fieldset>

            {watch("primaryType") === "normal" && (
              <div className="mb-3">
                <label className="form-label" htmlFor="text_problem_type">
                  Type
                </label>
                <select id="text_problem_type" {...register("type", defaultErrors.type)} required={true} className={"form-select" + (errors.type ? " is-invalid" : "")} defaultValue="">
                  <option hidden value="">
                    Choisissez un type de problème
                  </option>
                  <optgroup label="Parties-tout">
                    <option value="rdt">
                      {TextProblemTypeEnum.rdt} ({getOperators(TextProblemTypeEnum.rdt)})
                    </option>
                    <option value="rdp">
                      {TextProblemTypeEnum.rdp} ({getOperators(TextProblemTypeEnum.rdp)})
                    </option>
                    <option value="rdtpr">
                      {TextProblemTypeEnum.rdtpr} ({getOperators(TextProblemTypeEnum.rdtpr)})
                    </option>
                    <option value="rvp">
                      {TextProblemTypeEnum.rvp} ({getOperators(TextProblemTypeEnum.rvp)})
                    </option>
                    <option value="rnp">
                      {TextProblemTypeEnum.rnp} ({getOperators(TextProblemTypeEnum.rnp)})
                    </option>
                  </optgroup>
                  <optgroup label="Transformation">
                    <option value="refa">
                      {TextProblemTypeEnum.refa} ({getOperators(TextProblemTypeEnum.refa)})
                    </option>
                    <option value="reia">
                      {TextProblemTypeEnum.reia} ({getOperators(TextProblemTypeEnum.reia)})
                    </option>
                    <option value="rea">
                      {TextProblemTypeEnum.rea} ({getOperators(TextProblemTypeEnum.rea)})
                    </option>
                    <option value="refm">
                      {TextProblemTypeEnum.refm} ({getOperators(TextProblemTypeEnum.refm)})
                    </option>
                    <option value="reim">
                      {TextProblemTypeEnum.reim} ({getOperators(TextProblemTypeEnum.reim)})
                    </option>
                    <option value="rem">
                      {TextProblemTypeEnum.rem} ({getOperators(TextProblemTypeEnum.rem)})
                    </option>
                  </optgroup>
                  <optgroup label="Comparaison">
                    <option value="rpqa">
                      {TextProblemTypeEnum.rpqa} ({getOperators(TextProblemTypeEnum.rpqa)})
                    </option>
                    <option value="rgqa">
                      {TextProblemTypeEnum.rgqa} ({getOperators(TextProblemTypeEnum.rgqa)})
                    </option>
                    <option value="re">
                      {TextProblemTypeEnum.re} ({getOperators(TextProblemTypeEnum.re)})
                    </option>
                    <option value="rpqm">
                      {TextProblemTypeEnum.rpqm} ({getOperators(TextProblemTypeEnum.rpqm)})
                    </option>
                    <option value="rgqm">
                      {TextProblemTypeEnum.rgqm}({getOperators(TextProblemTypeEnum.rgqm)})
                    </option>
                    <option value="rdr">
                      {TextProblemTypeEnum.rdr} ({getOperators(TextProblemTypeEnum.rdr)})
                    </option>
                  </optgroup>
                </select>
                {errors.type && <div className="invalid-feedback d-block">{errors.type.message}</div>}
              </div>
            )}
          </div>

          {problem ? (
            <>
              {(!problem.isPrivate || problem.isPrivateWait) && (
                <>
                  {/* Response */}
                  <hr />
                  <div className="mb-3 form-floating">
                    <input
                      type="text"
                      {...register("response", defaultErrors.response)}
                      id="text_problem_response"
                      className={"form-control" + (errors.response ? " is-invalid" : "")}
                      placeholder="Reponse"
                      disabled={watch("isExclude") || problem.responseState === ResponseStateEnum.AnsweredByIa || problem.responseState === ResponseStateEnum.Conflict}
                    />
                    <label htmlFor="text_problem_response" className="form-label">
                      Reponse
                    </label>
                    {errors.response && <div className="invalid-feedback d-block">{errors.response.message}</div>}
                  </div>

                  {/* IsExclude */}
                  <div className="mb-3 form-check checkbox">
                    <input type="checkbox" {...register("isExclude")} className={"form-check-input" + (errors.isExclude ? " is-invalid" : "")} id="text_problem_isExclude" />
                    <label className="form-check-label" htmlFor="text_problem_isExclude">
                      Exclure de l'application
                    </label>
                    {errors.isExclude && <div className="invalid-feedback d-block">{errors.isExclude.message}</div>}
                  </div>
                </>
              )}

              <div className="text-center">
                <FormButtonLoading type="submit" className="btn btn-primary" isLoading={isLoading} label={titleLabel} />
                <FormButtonLoading type="button" className="btn btn-outline-ternary ms-2 ms-sm-5" onClick={handleCancel} isLoading={isLoading} label={cancelLabel} icone={faTrash} />
                {problem.email && <p className="mt-2">Proposé par : {problem.email}</p>}
              </div>
            </>
          ) : (
            <>
              <hr />
              <div className="text-center mb-3">
                <fieldset className="mb-3">
                  <legend className="col-form-label">Visibilité du problème</legend>
                  <Checkbox label="Public *" name="public" value="yes" register={register} error={errors.public} defaultErrors={defaultErrors.public} />
                  <Checkbox label="Privé" name="public" value="no" register={register} error={errors.public} defaultErrors={defaultErrors.public} />
                  {errors.public && <div className="invalid-feedback d-block">{errors.public.message}</div>}
                </fieldset>

                {watch("public") === "no" ? (
                  <FormButtonLoading type="submit" className="btn btn-primary" isLoading={isLoading} label="Enregistrer" icone={faFloppyDisk} />
                ) : (
                  <FormButtonLoading type="submit" className="btn btn-primary" isLoading={isLoading} label="Soumettre" icone={faShare} />
                )}
                <FormButtonLoading type="button" className="btn btn-outline-ternary ms-2 ms-sm-5" onClick={handleCancel} isLoading={isLoading} label="Annuler" icone={faTrash} showLoading={false} />
              </div>
              {watch("public") === "yes" && (
                <span id="helpPublic" className="fs-6 fst-italic">
                  * Le problème ne sera publié qu'après validation de l'administrateur.
                </span>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default TextProblemForm;
