import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDocumentTitle } from "../../hooks";
import { Link } from "react-router-dom";

function HelpTextProblem() {
  useDocumentTitle("Aide relative aux fonctionnalités de la banque de problèmes");

  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1>Banque de problèmes</h1>
      <p>Cette banque de problèmes collaborative vise à aider les enseignants dans le choix des problèmes à proposer à leurs élèves.</p>
      <span>Le classement des problèmes a été réalisé selon notre typologie : </span>
      <a href="https://www.mathsenvie.fr/?page_id=12118" rel="noreferrer" target="_blank">
        cliquer ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
      </a>
      <p className="mt-3">Ce module comporte trois fonctionnalités :</p>
      <ul>
        <li>la possibilité de consulter la banque de problèmes avec un accès par filtres ;</li>
        <li>la possibilité de soumettre ses propres problèmes à la communauté ;</li>
        <li>la possibilité de générer des fiches élèves et vidéoprojeter des problèmes pour la classe.</li>
      </ul>
      <p>
        À noter qu'il est possible, à l'image d'un réseau social, d'aimer certains problèmes (les plus populaires apparaissant en premier) et de commenter les énoncés (difficultés rencontrées avec les
        élèves, points de vigilance...).
      </p>
      <p className="mt-3">Selon votre profil, vous pourrez accéder aux contenus et fonctionnalités décrits ci-dessous.</p>

      <h2 className="mt-3">Sommaire</h2>
      <ul>
        <li className="mb-2">
          <Link to={{ hash: "consult" }}>Consulter</Link>
        </li>
        <li className="mb-2">
          <Link to={{ hash: "contribute" }}>Contribuer</Link>
        </li>
        <li>
          <Link to={{ hash: "edition" }}>Éditer et vidéoprojeter</Link>
        </li>
      </ul>

      <hr />

      <h2 id="consult" className="mt-3">
        Consulter
      </h2>
      <p>Pour accéder à cette fonctionnalité, utilisez le bouton en page d'accueil ou le menu.</p>
      <p>Par défaut, les problèmes auxquels vous avez accès sont affichés, en commençant par les problèmes les plus « aimés » de la communauté.</p>
      <p className="mt-3">Un clic sur le bouton « filtre » vous permet d'ouvrir la fenêtre des filtres, puis :</p>
      <ul>
        <li>sélectionnez la ou les banques à consulter : personnelle ou collaborative (selon votre profil) ;</li>
        <li>
          sélectionnez le ou les types de problèmes à afficher (plusieurs modes de sélection : par situation, par type ou par opération - les problèmes à étapes, de proportionnalité et avec des
          fractions étant à part) ;
        </li>
        <li>sélectionnez le niveau, du CP à la sixième ;</li>
        <li>cliquez sur « Filtrer ».</li>
      </ul>
      <div className="d-block text-center">
        <img src="img/consultHelp.webp" alt="Filtres de consultation" width="70%" />
      </div>
      <p className="mt-3">
        La liste de problèmes s'actualise alors en fonction de vos critères. Vous pouvez naviguer à travers les pages si tous les problèmes ne peuvent s'afficher sur une seule page.
      </p>
      <p className="mt-3">Pour chaque problème, vous avez accès aux informations suivantes :</p>
      <ul>
        <li>Niveau</li>
        <li>Type</li>
        <li>Opérations</li>
        <li>Auteur (pseudo)</li>
        <li>Nombre de « J'aime »</li>
        <li>Nombre de commentaires (un clic sur l'icône affiche l'ensemble des commentaires du problème)</li>
      </ul>
      <p className="mt-3">Vous pouvez, selon votre profil :</p>
      <ul>
        <li>copier le problème dans le cache pour le coller dans un document personnel ;</li>
        <li>ajouter une mention « J'aime » ;</li>
        <li>commenter le problème.</li>
      </ul>
      <p className="mt-3">Grâce au menu accessible via les trois points de suspension en haut à droite de chaque problème, vous pouvez :</p>
      <ul>
        <li>signaler le problème (en cas d'erreur notamment dans l'énoncé ou dans son classement) ;</li>
        <li>demander à rendre public votre problème afin qu'il apparaisse dans la banque collaborative (après acceptation par un administrateur) ;</li>
        <li>supprimer ou modifier votre problème, à condition qu'il ne soit pas encore rendu public.</li>
      </ul>

      <hr />

      <h2 id="contribute" className="mt-3">
        Contribuer
      </h2>
      <p>Vous pouvez contribuer à votre propre banque (banque personnelle) ou à la banque collaborative. Pour cette dernière, il y a deux façons de contribuer :</p>
      <ul>
        <li>soit en rédigeant un problème via la fonction « contribuer » (méthode 1) ;</li>
        <li>soit en soumettant un problème déjà présent dans votre banque personnelle (méthode 2).</li>
      </ul>

      <p>À noter que si vous avez contribué à au moins trois problèmes dans la banque collaborative, vous aurez accès à l'intégralité de celle-ci.</p>

      <p className="mt-3">
        <span className="fw-bold">Méthode 1 :</span> rédiger un problème via la fonction « Contribuer »
      </p>
      <p>Pour accéder à cette fonctionnalité, utilisez le bouton en page d'accueil ou le menu.</p>
      <p className="mt-3">S'ouvre alors la fenêtre « Ajouter un problème » :</p>
      <ul>
        <li>saisissez le titre ;</li>
        <li>saisissez l'énoncé du problème ;</li>
        <li>choisissez le niveau ;</li>
        <li>choisissez le type de problème (une seule case à cocher) ;</li>
        <li>
          enfin, optez pour « Public » si vous souhaitez que le problème soit soumis pour une publication dans la base collaborative, ou pour « Privé » si vous souhaitez que votre problème
          n'apparaisse que dans votre banque personnelle. Par défaut le bouton radio «Public» est activé.
        </li>
      </ul>
      <div className="d-block text-center">
        <img src="img/contributeHelp.webp" alt="Formulaire de contribution" width="50%" />
      </div>

      <p>
        <span className="fw-bold">Méthode 2 :</span> soumettre un problème déjà présent dans votre banque personnelle
      </p>
      <ul className="mt-3">
        <li>ouvrez le menu présent en haut à droite de votre problème (accessible via un clic sur les trois points de suspension) que vous souhaitez rendre public ;</li>
        <li>cliquez sur « Rendre public ».</li>
      </ul>
      <p className="mt-3">Attention, lorsque vous soumettez votre problème, il est étudié par un administrateur. Deux cas peuvent se présenter :</p>
      <ul>
        <li>votre problème est accepté tel quel : il apparaîtra dans votre banque personnelle et dans la banque collaborative ;</li>
        <li>votre problème est accepté, avec modification : il apparaîtra avec les modifications dans votre banque personnelle et dans la banque collaborative.</li>
      </ul>
      <p>À noter que l'administrateur se réserve le droit de modifier tous les champs de votre problème (titre, énoncé, niveau, type) avant validation.</p>

      <hr />

      <h2 id="edition" className="mt-3">
        Éditer et vidéoprojeter
      </h2>
      <p>Pour accéder à cette fonctionnalité, utilisez le bouton en page d'accueil ou le menu. Deux options s'offrent à vous :</p>
      <ul>
        <li>éditer un document imprimable à destination de vos élèves ;</li>
        <li>vidéoprojeter un ou des problèmes à destination de la classe.</li>
      </ul>
      <p className="mt-3">Selon votre profil, vous avez le choix entre 4 modèles d'édition PDF et 3 modes de vidéoprojection.</p>
      <ul>
        <li>Liste à imprimer avec grande police d'écriture</li>
        <li>Liste à imprimer avec petite police d'écriture</li>
        <li>Deux problèmes par page (résolution sur la feuille)</li>
        <li>Quatre problèmes par page</li>
        <li>Un problème à vidéoprojeter pour l'ensemble de la classe</li>
        <li>Deux problèmes à vidéoprojeter, un par niveau (avec possibilité d'intervertir les problèmes)</li>
        <li>Une liste de problèmes à vidéoprojeter pour l'ensemble de la classe</li>
      </ul>
      <div className="d-block text-center">
        <img src="img/editionTextProblemHelp.webp" alt="Choix d'édition" width="70%" />
      </div>
      <p className="mt-3">Pour éditer un de ces documents ou vidéo-projeter vos problèmes :</p>
      <ul>
        <li>sélectionnez le type de document que vous souhaitez éditer ou le type de vidéoprojection ;</li>
        <li>filtrez les problèmes (se référer à l'aide de « Consulter ») ;</li>
        <li>sélectionnez les problèmes grâce au bouton « Sélectionner » présent sous chaque problème. Votre panier se remplit, le compteur indiquant le nombre de problèmes sélectionnés ;</li>
        <li>
          cliquez sur « Générer » afin de télécharger directement sur votre PC le document au format imprimable ou « Projeter » afin d'obtenir un affichage plein écran. Les boutons « Générer » ou «
          Projeter » offrent une sous fonction permettant de faire un choix aléatoire qui se base sur votre filtrage.
        </li>
      </ul>
    </div>
  );
}

export default HelpTextProblem;
