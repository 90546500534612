import { CloseButton, Modal } from "react-bootstrap";
import { useUtils } from "../../hooks";
import { ModalPropsDefault } from "../../types";
import { Link } from "react-router-dom";

type MessageModalProps = {
  title: string;
  content: string;
  labelButton?: string;
  pathButton?: string;
};

function MessageModal({ show, closeModal, title, content, labelButton, pathButton }: ModalPropsDefault & MessageModalProps) {
  const { br, sanitize } = useUtils();

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header>
        <h4>{title}</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={sanitize(br(content))}></div>
        {pathButton && (
          <div className="d-grid gap-2 mt-3">
            <Link to={pathButton} className="btn btn-primary fw-bold">
              {labelButton}
            </Link>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default MessageModal;
