import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToAnchor() {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      return;
    }

    const hashId = location.hash.slice(1);

    if (hashId && document.getElementById(hashId)) {
      setTimeout(() => {
        const y = (document.getElementById(hashId)?.getBoundingClientRect().top ?? 0) + window.scrollY - 65;
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 200);
    }
  }, [location]);

  return null;
}

export default ScrollToAnchor;
