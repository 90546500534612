import { faAngleDoubleLeft, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useAuth } from "../contexts/auth";
import { Dispatch, SetStateAction, useEffect } from "react";
import { ProblemTypeEnum, ResponseStateEnum, TextProblemTypeEnum } from "../Utils";
import { generatePath, useNavigate } from "react-router-dom";
import * as Routes from "../routes";
import { Checkbox, CheckboxArray } from "./Checkbox";
import { TextProblemConsultFormValues } from "../types";

type TextProblemConsultFormProps = {
  isEdition: boolean;
  setFilters: (filters: TextProblemConsultFormValues) => void;
  setShowFilter: Dispatch<SetStateAction<boolean>>;
  filters: TextProblemConsultFormValues;
};

function TextProblemConsultForm({ isEdition, setFilters, setShowFilter, filters }: Readonly<TextProblemConsultFormProps>) {
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: filters,
  });

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (filters !== null) {
      reset(filters, { keepDefaultValues: true });
    }
  }, [filters, reset]);

  const onSubmit = (data: TextProblemConsultFormValues) => {
    if (!data["tag"].length && user) {
      data["tag"] = ["COMMUNITY_PRIVATE", "COMMUNITY_PUBLIC"];
    }
    setFilters(data);
    setShowFilter(false);
    navigate(generatePath(isEdition ? Routes.EDITION : Routes.CONSULT, { type: ProblemTypeEnum.TextProblem, page: "1" }), { replace: true });
  };

  const addition: (keyof typeof TextProblemTypeEnum)[] = ["rdt", "rdp", "refa", "reia", "rea", "rpqa", "rgqa", "re"];
  const multiply: (keyof typeof TextProblemTypeEnum)[] = ["rdtpr", "rvp", "rnp", "refm", "reim", "rem", "rpqm", "rgqm", "rdr"];
  const partieTout: (keyof typeof TextProblemTypeEnum)[] = ["rdt", "rdp", "rdtpr", "rvp", "rnp"];
  const transformation: (keyof typeof TextProblemTypeEnum)[] = ["refa", "reia", "rea", "refm", "reim", "rem"];
  const comparaison: (keyof typeof TextProblemTypeEnum)[] = ["rpqa", "rgqa", "re", "rpqm", "rgqm", "rdr"];

  const type = watch("type");

  const checker = (arr: (keyof typeof TextProblemTypeEnum)[], target: (keyof typeof TextProblemTypeEnum)[]) => target.every((v) => (arr ? arr.includes(v) : false));

  const onGroupChange = (e: React.ChangeEvent<HTMLInputElement>, group: (keyof typeof TextProblemTypeEnum)[]) => {
    if (e.target.checked) setValue("type", Array.from(new Set(type.concat(group).values())));
    else
      setValue(
        "type",
        type.filter((item) => !group.includes(item))
      );
  };

  return (
    <div className="form-template form-consult-problem">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="label-form position-relative">
          <h1 className="h3 text-center">Consulter les problèmes</h1>
          <button type="button" style={{ right: 25, top: "25%" }} className="btn btn-primary position-absolute" aria-label="Close" onClick={() => setShowFilter(false)}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </button>
        </div>
        <div className="alert-form"></div>
        <div className="body-form">
          {user?.isAdmin && (
            <div className="d-flex flex-wrap justify-content-center mb-3 ">
              <Checkbox name="responseState" register={register} value="all" label="Tout" />
              <Checkbox name="responseState" register={register} value={ResponseStateEnum.NoResponse} label="Aucune réponse" />
              <Checkbox name="responseState" register={register} value={ResponseStateEnum.AnsweredByHuman} label="Humain" />
              <Checkbox name="responseState" register={register} value={ResponseStateEnum.AnsweredByIa} label="IA" />
              <Checkbox name="responseState" register={register} value={ResponseStateEnum.Conflict} label="Conflit" />
              <Checkbox name="responseState" register={register} value={ResponseStateEnum.Verified} label="Vérifié" />
            </div>
          )}
          <div className="mb-3">
            <input type="text" {...register("textResearch")} className="form-control" placeholder="Rechercher dans l'énoncé" required autoFocus />
          </div>
          <div className="d-flex justify-content-evenly">
            <div className="form-check">
              <input type="checkbox" id="filter_tag_0" {...register("tag")} className="form-check-input" value="COMMUNITY_PRIVATE" disabled={!user} />
              <label className="form-check-label" htmlFor="filter_tag_0">
                Banque personnelle
              </label>
            </div>
            <div className="form-check">
              <input type="checkbox" id="filter_tag_1" {...register("tag")} className="form-check-input" value="COMMUNITY_PUBLIC" disabled={!user} />
              <label className="form-check-label" htmlFor="filter_tag_1">
                Banque collaborative
              </label>
            </div>
          </div>
          <div className="text-center">
            <hr />
            <p className="fw-bold">Problèmes basiques</p>
          </div>
          <div className="d-flex justify-content-around">
            <div>
              <input className="form-check-input checkbox" type="checkbox" id="addition" checked={checker(type, addition)} onChange={(e) => onGroupChange(e, addition)} />
              <label className="form-check-label ms-2 fst-italic" htmlFor="addition">
                Addition / Soustraction
              </label>
            </div>
            <div className="vr ms-2" style={{ width: 2 }}></div>
            <div>
              <input className="form-check-input checkbox" type="checkbox" id="multiply" checked={checker(type, multiply)} onChange={(e) => onGroupChange(e, multiply)} />
              <label className="form-check-label ms-2 fst-italic" htmlFor="multiply">
                Multiplication / Division
              </label>
            </div>
          </div>
          <div className="text-center">
            <input className="form-check-input checkbox" type="checkbox" id="reunion" checked={checker(type, partieTout)} onChange={(e) => onGroupChange(e, partieTout)} />
            <label className="form-check-label fst-italic ms-2" htmlFor="reunion">
              Parties-tout
            </label>
          </div>
          <div className="d-flex">
            <div className="col-6">
              <CheckboxArray name="type" register={register} value="rdt" label={TextProblemTypeEnum.rdt} />
              <CheckboxArray name="type" register={register} value="rdp" label={TextProblemTypeEnum.rdp} />
            </div>
            <div className="vr" style={{ width: 2 }}></div>
            <div className="ms-5">
              <CheckboxArray name="type" register={register} value="rdtpr" label={TextProblemTypeEnum.rdtpr} />
              <CheckboxArray name="type" register={register} value="rvp" label={TextProblemTypeEnum.rvp} />
              <CheckboxArray name="type" register={register} value="rnp" label={TextProblemTypeEnum.rnp} />
            </div>
          </div>
          <div className="text-center">
            <input className="form-check-input checkbox" type="checkbox" id="transformation" checked={checker(type, transformation)} onChange={(e) => onGroupChange(e, transformation)} />
            <label className="form-check-label fst-italic ms-2" htmlFor="transformation">
              Transformation
            </label>
          </div>
          <div className="d-flex">
            <div className="col-6">
              <CheckboxArray name="type" register={register} value="refa" label={TextProblemTypeEnum.refa} />
              <CheckboxArray name="type" register={register} value="reia" label={TextProblemTypeEnum.reia} />
              <CheckboxArray name="type" register={register} value="rea" label={TextProblemTypeEnum.rea} />
            </div>
            <div className="vr" style={{ width: 2 }}></div>
            <div className="ms-5">
              <CheckboxArray name="type" register={register} value="refm" label={TextProblemTypeEnum.refm} />
              <CheckboxArray name="type" register={register} value="reim" label={TextProblemTypeEnum.reim} />
              <CheckboxArray name="type" register={register} value="rem" label={TextProblemTypeEnum.rem} />
            </div>
          </div>
          <div className="text-center">
            <input className="form-check-input checkbox" type="checkbox" id="comparaison" checked={checker(type, comparaison)} onChange={(e) => onGroupChange(e, comparaison)} />
            <label className="form-check-label fst-italic ms-2" htmlFor="comparaison">
              Comparaison
            </label>
          </div>
          <div className="d-flex">
            <div className="col-6">
              <CheckboxArray name="type" register={register} value="rpqa" label={TextProblemTypeEnum.rpqa} />
              <CheckboxArray name="type" register={register} value="rgqa" label={TextProblemTypeEnum.rgqa} />
              <CheckboxArray name="type" register={register} value="re" label={TextProblemTypeEnum.re} />
            </div>
            <div className="vr" style={{ width: 2 }}></div>
            <div className="ms-5">
              <CheckboxArray name="type" register={register} value="rpqm" label={TextProblemTypeEnum.rpqm} />
              <CheckboxArray name="type" register={register} value="rgqm" label={TextProblemTypeEnum.rgqm} />
              <CheckboxArray name="type" register={register} value="rdr" label={TextProblemTypeEnum.rdr} />
            </div>
          </div>
          <hr />
          <div>
            <div className="d-flex justify-content-center fw-bold">
              <CheckboxArray name="type" register={register} value="step" label={TextProblemTypeEnum.step} className="me-2" />
              <CheckboxArray name="type" register={register} value="propor" label={TextProblemTypeEnum.propor} className="ms-1 me-2" />
              <CheckboxArray name="type" register={register} value="frac" label={TextProblemTypeEnum.frac} className="ms-1 me-2" />
              <CheckboxArray name="type" register={register} value="english" label={TextProblemTypeEnum.english} className="ms-1 me-2" />
            </div>
            <hr />
            <div className="d-flex justify-content-center">
              <CheckboxArray name="nivel" register={register} value="cp" label="CP" className="ms-1 me-2" />
              <CheckboxArray name="nivel" register={register} value="ce1" label="CE1" className="ms-1 me-2" />
              <CheckboxArray name="nivel" register={register} value="ce2" label="CE2" className="ms-1 me-2" />
              <CheckboxArray name="nivel" register={register} value="cm1" label="CM1" className="ms-1 me-2" />
              <CheckboxArray name="nivel" register={register} value="cm2" label="CM2" className="ms-1 me-2" />
              <CheckboxArray name="nivel" register={register} value="6eme" label="Sixième" className="ms-1 me-2" />
            </div>
          </div>
          <div className="text-center">
            <button className="btn btn-primary mt-2" type="submit">
              <FontAwesomeIcon icon={faFilter} /> Filtrer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TextProblemConsultForm;
